import React from 'react';
import { Link } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { toast } from 'react-toastify';

import { printError } from '../utils';
import { routes } from '../utils/constants';

import css from './ListingSettings.module.css';

export const ListingSettings = ({ id }) => {
  const remove = async () => {
    if (window.confirm('Prajete si zmazať túto položku?')) {
      // TODO: spinner
      try {
        await firebase.firestore().doc(`services/${id}`).delete();
        toast('Položka bola zmazaná', { type: toast.TYPE.SUCCESS });
      } catch (e) {
        printError(e);

        toast('Pri mazaní nastala chyba', { type: toast.TYPE.ERROR });
      } finally {
        // TODO: spinner
      }
    }
  };

  return (
    <ul className={css.buttons}>
      <li className={css.edit}>
        <Link to={`${routes.item}/${id}`}>Upraviť</Link>
      </li>
      <li className={css.remove}>
        <button onClick={remove}>Odstrániť</button>
      </li>
    </ul>
  );
};
