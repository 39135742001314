import React from 'react';
import { FaEnvelope, FaPhoneVolume, FaLink, FaClock } from 'react-icons/fa';
import { MdPlace } from 'react-icons/md';
import { useAuth } from '../app';
import { ListingSettings } from './ListingSettings';
import css from './Listing.module.css';
import classnames from 'classnames';
import { getLink } from '../utils/common';

export const Listing = ({ values, loading, error }) => {
  const { isLoggedIn, user } = useAuth();

  return (
    <ul className={css.cards}>
      {loading && <li>Načítavam nové dáta.</li>}

      {error && <li>Nastala chyba pri načítaní dát.</li>}

      {!loading && values && values.length === 0 && <li>Žiadna položka nebola nájdená.</li>}

      {values &&
        values.map(({ id, name, description, author, phone, email, web, hours, sectors, area }) => (
          <li key={id} className={classnames(css.card)}>
            <h3 className={css.title}>{name}</h3>
            <div className={css.content}>
              <ul className={css.meta}>
                {phone && (
                  <li>
                    <FaPhoneVolume className={css.icon} />
                    {phone}
                  </li>
                )}
                {email && (
                  <li>
                    <FaEnvelope className={css.icon} />
                    <a href={'mailto:' + email}>{email}</a>
                  </li>
                )}
                {web && (
                  <li>
                    <FaLink className={css.icon} />
                    <a href={getLink(web)} target="_new">
                      {web}
                    </a>
                  </li>
                )}
                {hours && (
                  <li>
                    <FaClock className={css.icon} />
                    {hours}
                  </li>
                )}
                {area && (
                  <li title={area.join(', ')}>
                    <MdPlace className={css.icon} />
                    <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {area.join(', ')}
                    </span>
                  </li>
                )}
              </ul>
              <div
                className={css.description}
                dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
              />
            </div>
            {sectors && (
              <ul className={css.tags}>
                {sectors.map((sector) => (
                  <li className={css.tag} key={sector}>
                    {sector}
                  </li>
                ))}
              </ul>
            )}
            {isLoggedIn && user.uid === author && <ListingSettings id={id} />}
          </li>
        ))}
    </ul>
  );
};
