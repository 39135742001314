import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { MdLibraryAdd } from 'react-icons/md';
import { IoIosLogOut } from 'react-icons/io';

import { useAuth } from '../app';
import { Button } from '../components/Button';
import { printError } from '../utils';
import { routes } from '../utils/constants';

import css from './Profile.module.css';

export const Profile = ({ verifyEmail }) => {
  const { state, user, sendEmailVerification, logout } = useAuth();

  useEffect(() => {
    const verify = async () => {
      if (verifyEmail && user.email === verifyEmail) {
        try {
          const idTokenResult = await user.getIdTokenResult(true);

          if (idTokenResult) {
            await user.reload();
            toast('Váš účet bol úspešne verifikovaný', { type: toast.TYPE.SUCCESS });
          }
        } catch (e) {
          printError(e);
          toast('Pri verifikácii účtu nastala chyba', { type: toast.TYPE.ERROR });
        }
      }
    };

    verify();
  }, [user, verifyEmail]);

  const handleSendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      toast('Verifikačný email bol odoslaný', { type: toast.TYPE.INFO });
    } catch (e) {
      printError(e);
      toast('Verifikačný email sa nepodarilo odoslať', { type: toast.TYPE.ERROR });
    }
  };

  return (
    <div className={css.profile}>
      <p>
        <strong className={css.name}>{user.displayName}</strong>, <span>{user.email}</span>
      </p>

      {state === 'authenticated' && (
        <>
          <p>
            Prosím, potvrďte verifikačný odkaz, ktorý vám prišiel na email (môže to trvať niekoľko minút) a pokračujte
            podľa pokynov.
          </p>
          <p>V prípade, že nami zaslaný email nevidíte v priečinku prijatých správ, skontrolujte si priečinok Spam.</p>
          <p>
            Poprípade môžte{' '}
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                handleSendVerificationEmail();
              }}
            >
              zaslať nový verifikačný email
            </a>
            .
          </p>
        </>
      )}

      <ul className={css.menu}>
        <li>
          <Button to={`${routes.item}${routes.add}`} type="main">
            <MdLibraryAdd /> Pridať inzerát
          </Button>
        </li>
        <li>
          <Button
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            <IoIosLogOut /> Odhlásiť sa
          </Button>
        </li>
      </ul>
    </div>
  );
};
