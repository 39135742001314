import React from 'react';
import { Link } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../app';
import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Listing } from '../components/Listing';
import { routes } from '../utils/constants';
import { Profile } from '../components/Profile';

const title = 'Profil';

export default ({ location }) => {
  const { search } = location;
  const { isLoggedIn, state, user } = useAuth();

  const [values, loading, error] = useCollectionData(
    user ? firebase.firestore().collection('services').where('author', '==', user.uid) : null,
    {
      idField: 'id',
    }
  );

  return (
    <Main>
      <Meta title={title} />
      <h1>{title}</h1>

      {isLoggedIn ? (
        <>
          <Profile verifyEmail={search ? search.split('=')[1] : undefined} />

          {state === 'verified' && (
            <>
              {values && values.length > 0 && <h2>Vaše položky</h2>}
              <Listing values={values} loading={loading} error={error} />
            </>
          )}
        </>
      ) : (
        <p>
          Je nutné sa najprv <Link to={routes.login}>prihlásiť</Link>.
        </p>
      )}
    </Main>
  );
};
